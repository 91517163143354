import { loan_application } from '@axo/shared/data-access/types';
import {
  Step1DataCCNO,
  Step2DataCCNO,
  Step3DataCCNO,
} from '../../../forms/Norway/CreditCard/FormData.types';
import type { TStepFields } from '../formFields.d';

const step1FieldsNOCC: TStepFields<Step1DataCCNO> = [
  {
    id: 'Customer/Email',
  },
  {
    id: 'Customer/MobilePhoneNumber',
  },
  {
    id: 'Person/SocialSecurityNumber',
  },
  {
    id: 'LoanApplication/AppliedAmount',
  },
];

const step2FieldsNOCC: TStepFields<Step2DataCCNO> = [
  {
    id: 'LoanApplication/EmploymentStatus',
  },
  {
    id: 'LoanApplication/EmploymentStatusSince',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Entrepreneur,
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.PermanentPublic,
          loan_application.EmploymentStatus.Temporary,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/Employer',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Entrepreneur,
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.PermanentPublic,
          loan_application.EmploymentStatus.Temporary,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/GrossIncomeYearly',
  },

  {
    id: 'LoanApplication/PartnerGrossIncomeYearly',
    parents: [
      {
        id: 'LoanApplication/MaritalStatus',
        values: [
          loan_application.MaritalStatus.Cohabiting,
          loan_application.MaritalStatus.Married,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/ExtraIncomeRental',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.CooperativeApartment,
          loan_application.Residence.OwnApartment,
          loan_application.Residence.OwnHouse,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/ExtraIncomeChildSupport',
    parents: [
      {
        id: 'AmountOfChildren',
        values: ['1', '2', '3', '4', '5'],
      },
    ],
  },
];

const step3FieldsNOCC: TStepFields<Step3DataCCNO> = [
  {
    id: 'Frontend/MortgageDebt',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.OwnHouse,
          loan_application.Residence.OwnApartment,
          loan_application.Residence.PartnersHouse,
          loan_application.Residence.CompanyOwnedFlat,
          loan_application.Residence.Cohabitant,
          loan_application.Residence.Tenant,
          loan_application.Residence.CooperativeApartment,
        ],
      },
    ],
  },
  {
    id: 'Debt/Housing/OutstandingAmount',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.OwnHouse,
          loan_application.Residence.OwnApartment,
          loan_application.Residence.PartnersHouse,
          loan_application.Residence.CompanyOwnedFlat,
          loan_application.Residence.Cohabitant,
          loan_application.Residence.Tenant,
          loan_application.Residence.CooperativeApartment,
        ],
      },
      {
        id: 'Frontend/MortgageDebt',
        values: ['Yes'],
      },
    ],
  },
  {
    id: 'Frontend/VehicleDebt',
  },
  {
    id: 'Debt/CarMCBoat/OutstandingAmount',
    parents: [
      {
        id: 'Frontend/VehicleDebt',
        values: ['Yes'],
      },
    ],
  },
  {
    id: 'Frontend/StudentDebt',
  },
  {
    id: 'Debt/Student/OutstandingAmount',
    parents: [
      {
        id: 'Frontend/StudentDebt',
        values: ['Yes'],
      },
    ],
  },
];

export { step1FieldsNOCC, step2FieldsNOCC, step3FieldsNOCC };
