import { Product } from '@axo/shared/data-access/types';
import type { TFormFields } from './formFields.d';
import {
  step1FieldsDK,
  step3FieldsDK,
  step4FieldsDK,
  step5FieldsDK,
  step6FieldsDK,
  step7FieldsDK,
  step8FieldsDK,
  step9FieldsDK,
} from './formFieldsDK';
import {
  step1FieldsFI,
  step2FieldsFI,
  step3FieldsFI,
  step4FieldsFI,
  step5FieldsFI,
  step6FieldsFI,
} from './formFieldsFI';
import {
  step1FieldsNO,
  step1FieldsNOCC,
  step2FieldsNO,
  step2FieldsNOCC,
  step3FieldsNO,
  step3FieldsNOCC,
  step4FieldsNO,
  step5FieldsNO,
} from './formFieldsNO';
import {
  step1FieldsSE,
  step2FieldsSE,
  step3FieldsSE,
  step4FieldsSE,
  step5FieldsSE,
} from './formFieldsSE';

const formFields: TFormFields = {
  SE: {
    [Product.UnsecuredLoan]: [
      step1FieldsSE,
      step2FieldsSE,
      step3FieldsSE,
      step4FieldsSE,
      step5FieldsSE,
    ],
  },
  DK: {
    [Product.UnsecuredLoan]: [
      step1FieldsDK,
      // step2FieldsDK,
      step3FieldsDK,
      step4FieldsDK,
      step5FieldsDK,
      step6FieldsDK,
      step7FieldsDK,
      step8FieldsDK,
      step9FieldsDK,
    ],
  },
  NO: {
    [Product.UnsecuredLoan]: [
      step1FieldsNO,
      step2FieldsNO,
      step3FieldsNO,
      step4FieldsNO,
      step5FieldsNO,
    ],
    [Product.CreditCard]: [step1FieldsNOCC, step2FieldsNOCC, step3FieldsNOCC],
  },
  FI: {
    [Product.UnsecuredLoan]: [
      step1FieldsFI,
      step2FieldsFI,
      step3FieldsFI,
      step4FieldsFI,
      step5FieldsFI,
      step6FieldsFI,
    ],
  },
};

export default formFields;
