import { useTranslation } from '@axo/shared/i18n';
import { Icon } from '@axo/ui-core/components/Icon';
import { Text } from '@axo/ui-core/components/typography';
import { Trans } from 'react-i18next';
import styles from './keyBenefit.module.scss';

/** @deprecated use `@axo/ui-feature/USPsList` */
export const KeyBenefit = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.keyBenefit}>
      <Icon name="check" size="s" />
      <Text>
        <Trans t={t}>{text}</Trans>
      </Text>
    </div>
  );
};
