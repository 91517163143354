export enum Residence {
  OwnHouse = 'Own house',
  OwnApartment = 'Own apartment',
  Rental = 'Rental',
  PartnersHouse = 'Partners House',
  CompanyOwnedFlat = 'Company owned flat',
  Cohabitant = 'Cohabitant',
  Tenant = 'Tenant',
  CooperativeApartment = 'Cooperative apartment',
}
