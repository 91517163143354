import {
  Education,
  EmploymentStatus,
  MaritalStatus,
  Residence,
} from '@axo/shared/data-access/types';
import type {
  Step1Data as Step1DataNO,
  Step2Data as Step2DataNO,
  Step3Data as Step3DataNO,
  Step4Data as Step4DataNO,
  Step5Data as Step5DataNO,
} from '../../../forms/Norway/LoanApplication/FormData.no.types';
import type { TStepFields } from '../formFields.d';

const step1FieldsNO: TStepFields<Step1DataNO> = [
  {
    id: 'LoanApplication/AppliedAmount',
  },
  {
    id: 'LoanApplication/LoanDuration',
  },
  {
    id: 'LoanApplication/InitialPurpose',
    isOptional: true,
  },
  {
    id: 'LoanApplication/Purpose',
    isOptional: true,
  },
  {
    id: 'Customer/Email',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Customer/MobilePhoneNumber',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/ShowMarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
];

const step2FieldsNO: TStepFields<Step2DataNO> = [
  {
    id: 'Person/SocialSecurityNumber',
  },
  {
    id: 'Frontend/IsNorwegian',
    isOptional: true,
  },
  {
    id: 'LoanApplication/Citizenship',
    parents: [{ id: 'Frontend/IsNorwegian', values: ['No'] }],
  },
  {
    id: 'LoanApplication/LivedInMarketCountrySince',
    parents: [{ id: 'Frontend/IsNorwegian', values: ['No'] }],
  },
  {
    id: 'LoanApplication/EmploymentStatus',
  },
  {
    id: 'LoanApplication/Employer',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          EmploymentStatus.Entrepreneur,
          EmploymentStatus.Temporary,
          EmploymentStatus.Permanent,
          EmploymentStatus.PermanentPublic,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/EmploymentStatusSince',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          EmploymentStatus.Entrepreneur,
          EmploymentStatus.Temporary,
          EmploymentStatus.Permanent,
          EmploymentStatus.PermanentPublic,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/Education',
  },
  {
    id: 'LoanApplication/GrossIncomeYearly',
  },
];
const step3FieldsNO: TStepFields<Step3DataNO> = [
  // PartnerFormGroup
  {
    id: 'LoanApplication/MaritalStatus',
  },
  {
    id: 'LoanApplication/PartnerGrossIncomeYearly',
    parents: [
      {
        id: 'LoanApplication/MaritalStatus',
        values: [MaritalStatus.Married, MaritalStatus.Cohabiting],
      },
    ],
  },
  {
    id: 'AmountOfChildren',
  },
  {
    id: 'LoanApplication/ExtraIncomeChildSupport',
    parents: [{ id: 'AmountOfChildren', values: ['1', '2', '3', '4', '5'] }],
  },

  // HousingFormGroup
  {
    id: 'LoanApplication/HousingType',
  },
  {
    id: 'Person/LivedAtAddressSince',
  },
  {
    id: 'LoanApplication/ExtraIncomeRental',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          Residence.CooperativeApartment,
          Residence.OwnApartment,
          Residence.OwnHouse,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/RentCostMonthly',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          Residence.Rental,
          Residence.CooperativeApartment,
          Residence.OwnApartment,
        ],
      },
    ],
  },
  {
    id: 'Debt/Housing/OutstandingAmount',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          Residence.CooperativeApartment,
          Residence.OwnApartment,
          Residence.OwnHouse,
        ],
      },
    ],
  },

  // DebtFormGroup
  {
    id: 'Debt/Student/OutstandingAmount',
    parents: [
      {
        id: 'LoanApplication/Education',
        values: [Education.EducationHigherEducation],
      },
    ],
  },
  {
    id: 'Frontend/HasCarDebt',
    isOptional: true,
  },
  {
    id: 'Debt/CarMCBoat/OutstandingAmount',
    parents: [
      {
        id: 'Frontend/HasCarDebt',
        values: ['Yes'],
      },
    ],
  },
];

const step4FieldsNO: TStepFields<Step4DataNO> = [
  {
    id: 'LoanApplication/Debts',
    isOptional: true,
  },
  {
    id: 'Debt/Personal/OutstandingAmount',
  },
];

const step5FieldsNO: TStepFields<Step5DataNO> = [];

export {
  step1FieldsNO,
  step2FieldsNO,
  step3FieldsNO,
  step4FieldsNO,
  step5FieldsNO,
};
