import { loan_application } from '@axo/shared/data-access/types';
import type {
  Step1Data as Step1DataSE,
  Step2Data as Step2DataSE,
  Step3Data as Step3DataSE,
  Step4Data as Step4DataSE,
  Step5Data as Step5DataSE,
} from '../../forms/Sweden/LoanApplication/FormData.se.types';
import type { TStepFields } from './formFields.d';

const step1FieldsSE: TStepFields<Step1DataSE> = [
  {
    id: 'LoanApplication/AppliedAmount',
  },
  {
    id: 'LoanApplication/LoanDuration',
  },
  {
    id: 'LoanApplication/InitialPurpose',
    isOptional: true,
  },
  {
    id: 'Debt/Unspecified/RefinanceAmount',
    parents: [
      {
        id: 'LoanApplication/InitialPurpose',
        values: [loan_application.Purpose.RefinancingLoan],
      },
    ],
  },
  {
    id: 'LoanApplication/Purpose',
    parents: [
      {
        id: 'LoanApplication/InitialPurpose',
        values: [loan_application.Purpose.Other],
      },
    ],
    isOptional: true,
  },
  {
    id: 'Customer/Email',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Customer/MobilePhoneNumber',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/ShowMarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
];

const step2FieldsSE: TStepFields<Step2DataSE> = [
  {
    id: 'Person/SocialSecurityNumber',
  },
  {
    id: 'LoanApplication/MaritalStatus',
  },
  {
    id: 'AmountOfChildren',
  },
];

const step3FieldsSE: TStepFields<Step3DataSE> = [
  { id: 'LoanApplication/HousingType' },
  {
    id: 'LoanApplication/RentCostMonthly',
    parents: [{ id: 'LoanApplication/HousingType' }],
  },
];

const step4FieldsSE: TStepFields<Step4DataSE> = [
  {
    id: 'LoanApplication/EmploymentStatus',
  },
  {
    id: 'Frontend/GrossIncomeMonthly',
  },
  {
    id: 'LoanApplication/Employer',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/EmploymentStatusSince',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/EmploymentStatusUntil',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [loan_application.EmploymentStatus.Temporary],
      },
    ],
  },
];

const step5FieldsSE: TStepFields<Step5DataSE> = [
  {
    id: 'LoanApplication/InitialPurpose',
    parents: [{ id: 'LoanApplication/Purpose' }],
  },
  {
    id: 'LoanApplication/Purpose',
    parents: [
      {
        id: 'LoanApplication/InitialPurpose',
        values: ['No'],
      },
      {
        id: 'LoanApplication/InitialPurpose',
      },
    ],
  },
  { id: 'Frontend/AddAccountNumber' },

  {
    id: 'LoanApplication/AccountClearingNumber',
    parents: [{ id: 'Frontend/AddAccountNumber', values: ['Yes'] }],
  },
  {
    id: 'LoanApplication/AccountNumber',
    parents: [{ id: 'Frontend/AddAccountNumber', values: ['Yes'] }],
  },

  { id: 'Frontend/AddCoApplicant' },
  {
    id: 'LoanApplication/CoApplicantIsCohabitant',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'CoCustomer/MobilePhoneNumber',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'CoCustomer/Email',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantMaritalStatus',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'CoPerson/SocialSecurityNumber',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantHousingType',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'LoanApplication/CoApplicantIsCohabitant', values: ['Yes'] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantRentCostMonthly',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'LoanApplication/CoApplicantIsCohabitant', values: ['Yes'] },
      { id: 'LoanApplication/CoApplicantHousingType', values: [] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantEmploymentStatus',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'Frontend/CoApplicantGrossIncomeMonthly',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantEmployer',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantEmploymentStatusSince',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantEmploymentStatusUntil',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [loan_application.EmploymentStatus.Temporary],
      },
    ],
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
    parents: [{ id: 'Ignore/MarketingConsent', values: [false] }],
  },
  {
    id: 'Ignore/ShowMarketingConsent',
    isOptional: true,
    parents: [{ id: 'Ignore/MarketingConsent', values: [false] }],
  },
];

export {
  step1FieldsSE,
  step2FieldsSE,
  step3FieldsSE,
  step4FieldsSE,
  step5FieldsSE,
};
