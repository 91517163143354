export enum Purpose {
  RefinancingLoan = 'Refinancing loan',
  Renovation = 'Renovation',
  Vehicle = 'Vehicle',
  Other = 'Other',
  Holiday = 'Holiday',
  UnexpectedExpenses = 'Unexpected expenses',
  RealEstate = 'Real estate',
  Medical = 'Medical',
  SpecialOccasion = 'Special occasion',
  Investment = 'Investment',
  GreenEnergy = 'Green energy',
}
