import { ApplicationStatus } from './ApplicationStatus';
import { Car } from './Car';
import { ChannelSnapshot } from './ChannelSnapshot';
import { CreateChild } from './Child';
import { Currency } from './Currency';
import { UpdateDebt } from './Debt';
import { Education } from './Education';
import { EmploymentStatus } from './EmploymentStatus';
import { Expense } from './Expense';
import { JobPosition } from './JobPosition';
import { Lead } from './Lead';
import { MaritalStatus } from './MaritalStatus';
import { Purpose } from './Purpose';
import { Residence } from './Residence';

// TODO: be more specific with these types

export enum Product {
  UnsecuredLoan = 'UnsecuredLoan',
  CreditCard = 'CreditCard',
}

export enum DataFetchedFrom {
  'Uniify' = 1,
  'Gjeldsregisteret' = 2,
}

export interface CreateLoanApplication {
  CustomerID: string;
  PersonID?: string | null;
  LegacyID?: number | null;
  Status?: ApplicationStatus | null;
  Currency?: Currency | null;
  AppliedAmount?: number | null;
  LoanDuration?: number | null;
  EmploymentStatus?: EmploymentStatus | null;
  EmploymentStatusSince?: string | null;
  EmploymentStatusUntil?: string | null;
  Employer?: string | null;
  JobTitle?: string | null;
  JobPosition?: JobPosition | null;
  GrossIncomeMonthly?: number | null;
  GrossIncomeYearly?: number | null;
  NetIncomeMonthly?: number | null;
  MaritalStatus?: MaritalStatus | null;
  HousingType?: Residence | null;
  PartnerGrossIncomeMonthly?: number | null;
  PartnerGrossIncomeYearly?: number | null;
  PartnerNetIncomeYearly?: number | null;
  LivingCostMonthly?: number | null;
  RentCostMonthly?: number | null;
  OwnerAssociationCostMonthly?: number | null;
  ExtraIncomeMonthly?: number | null;
  ExtraIncomeRental?: number | null;
  ExtraIncomeChildSupport?: number | null;
  CompanyOrganizationNumber?: string | null;
  InitialPurpose?: Purpose | null;
  Purpose?: Purpose | null;
  OtherPurpose?: Purpose | null;
  AccountNumber?: string | null;
  AcceptTermsConditions?: boolean | null;
  TermsAndConditionsText?: string | null;
  MarketingConsentID?: string | null;
  Education?: Education | null;
  MilitaryServiceCompleted?: boolean | null;
  HasChildren?: boolean | null;
  HasCars?: boolean | null;
  LivedInMarketCountrySince?: string | null;
  Citizenship?: string | null;
  ResidencePermit?: number | null;
  ResidencePermitType?: string | null;
  ResidencePermitNumber?: string | null;
  ResidencePermitExpiration?: string | null;
  MarketCountry: string;
  HumanReadableID?: string | null;
  PaymentModelProcessed?: boolean | null;
  EmailReminders?: boolean | null;
  SMSReminders?: boolean | null;
  AccountClearingNumber?: string | null;
  SourceLoanApplicationID?: string | null;
  MagicToken?: string | null;
  WaitingForBanks?: boolean;
  OwnsHolidayHome?: boolean | null;
  CurrentYearDebtOutstandingAmount?: number | null;
  TotalRefinancingAmount?: number | null;

  CoApplicantCustomerID?: string | null;
  CoApplicantPersonID?: string | null;
  CoApplicantIsCohabitant?: boolean | null;
  CoApplicantMaritalStatus?: MaritalStatus | null;
  CoApplicantCitizenship?: string | null;
  CoApplicantLivedInMarketCountrySince?: string | null;
  CoApplicantEmploymentStatus?: EmploymentStatus | null;
  CoApplicantEmploymentStatusSince?: string | null;
  CoApplicantEmploymentStatusUntil?: string | null;
  CoApplicantGrossIncomeYearly?: number | null;
  CoApplicantEmployer?: string | null;
  CoApplicantEducation?: Education | null;
  CoApplicantPartnerGrossIncomeYearly?: number | null;
  CoApplicantNetIncomeYearly?: number | null;
  CoApplicantHousingType?: Residence | null;
  CoApplicantLivingCostMonthly?: number | null;
  CoApplicantRentCostMonthly?: number | null;
  CoApplicantOwnerAssociationCostMonthly?: number | null;
  CoApplicantResidencePermit?: number | null;
  CoApplicantResidencePermitType?: number | null;
  CoApplicantResidencePermitNumber?: string | null;
  CoApplicantResidencePermitExpiration?: string | null;
  CoApplicantHasUnemploymentFund?: boolean | null;
  CoApplicantCompanyOrganizationNumber?: string | null;

  Debts?: UpdateDebt[];
  CoDebts?: UpdateDebt[];
  Children?: CreateChild[];
  Cars?: Car[];
  Leads?: Lead[];

  YearsAsBankCustomer?: number | null;
  HasDankort?: boolean | null;
  HasOtherPaymentCards?: boolean | null;
  RegistrationNumber?: number | null;
  HasUnemploymentFund?: boolean | null;
  PaymentRemarks?: boolean | null;
  CreditStatusConsent?: boolean | null;

  Expenses?: Expense[];

  DataFetchedFrom?: DataFetchedFrom;
}

export interface LoanApplication extends CreateLoanApplication {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  CompletedAt?: string;
  Revision?: Readonly<number>;
  IP?: string;
  Product: Product;
  Channels?: ChannelSnapshot[];
}

export interface LoanRequestApplication extends CreateLoanApplication {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  Debts?: UpdateDebt[];
  CoDebts?: UpdateDebt[];
  Product: Product;
}

export type UpdateLoanApplication = Partial<CreateLoanApplication>;

export interface MagicOutput extends LoanApplication {
  JWT: string;
}

export enum ArchiveRequestType {
  WithdrawnByCustomer = 'WithdrawnByCustomer',
  AutomaticEndStatus = 'AutomaticEndStatus',
}
