'use client';

import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { Spinner } from '../Spinner';
import styles from './button.module.scss';

type NextLinkType = typeof import('next/link').default | null;
let NextLink: NextLinkType = null;

/**
 * Dynamically imports the Next.js Link component.
 * This allows the button component to integrate with Next.js routing only if Next.js is available.
 * Wrapped in an async IIFE to avoid blocking the main thread.
 */
(async () => {
  try {
    const module: { default: NextLinkType } = await import('next/link');
    NextLink = module.default;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // not in Next.js environment
  }
})();

/**
 * Options specific to Next.js Link component.
 */
type LinkOptions = {
  /**
   * Replaces the current history state instead of adding a new URL into the stack.
   * @default false
   * @see {@link https://nextjs.org/docs/app/api-reference/components/link#replace}
   */
  replace?: boolean;
  /**
   * Scrolls to the top of the page after a navigation, when true.
   * If false, maintains the current scroll position.
   * @default true
   * @see {@link https://nextjs.org/docs/app/api-reference/components/link#scroll}
   */
  scroll?: boolean;
  /**
   * Prefetches the page in the background. If `true`, prefetching occurs.
   * `null` can be used to disable prefetching.
   * @default true
   * @see {@link https://nextjs.org/docs/app/api-reference/components/link#prefetch}
   */
  prefetch?: boolean | null;
};

/**
 * Button component Props.
 */
export type ButtonProps = {
  children: ReactNode;
  variant?: (typeof variants)[number];
  size?: (typeof sizes)[number] | (typeof sizes_n)[number];
  state?: (typeof states)[number];
  prefix?: ReactElement;
  suffix?: ReactElement;
  /** Indicates if the button only contains an icon (removes extra padding) */
  iconOnly?: boolean;
  /** Shows a loading spinner inside the button */
  loading?: boolean;
  /** The URL for the link if the button should render as an anchor tag */
  href?: string;
  /** Additional Link options when using Next.js Link */
  linkOptions?: LinkOptions;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'prefix'> &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'prefix'>;

export const variants = ['primary', 'secondary', 'tertiary', 'ghost'] as const;
/** @deprecated @todo replace all usages, small = m | medium = l | large (64) = xl (56) */
export const sizes = ['small', 'medium', 'large'] as const;
export const sizes_n = ['xs', 's', 'm', 'l', 'xl'] as const;
export const states = ['neutral', 'success', 'warning'] as const;

/**
 * Button component supports different variants, sizes, and loading states.
 * It can function as a standard button, or as a link when provided with an `href` prop.
 *
 * The Button can also integrate with Next.js routing by leveraging the Next.js `Link` component,
 * which is dynamically imported if available.
 */
export const Button = ({
  children,
  type = 'button',
  variant = 'primary',
  size = 'medium',
  state = 'neutral',
  prefix,
  suffix,
  iconOnly = false,
  loading = false,
  href,
  linkOptions = {},
  className,
  ...props
}: ButtonProps) => {
  if (iconOnly && props['aria-label'] === undefined) {
    throw new Error(
      'When using the `iconOnly` prop, you must provide an `aria-label`'
    );
  }

  const isExternal =
    href?.startsWith('http://') || href?.startsWith('https://');

  const Element = href
    ? isExternal || !NextLink
      ? 'a'
      : (NextLink as React.ElementType)
    : 'button';

  return (
    <Element
      {...(Element === 'button' ? { type } : { href })}
      {...(Element === NextLink ? linkOptions || {} : {})}
      className={clsx(styles.button, className)}
      data-variant={variant}
      data-size={size}
      data-state={state}
      data-icon-only={iconOnly}
      data-loading={loading}
      {...(Element === 'button' ? { disabled: loading || props.disabled } : {})}
      {...props}
    >
      {loading && <Spinner className={styles.prefix} />}
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      <span className={styles.label}>{children}</span>
      {suffix && <span className={styles.suffix}>{suffix}</span>}
    </Element>
  );
};
